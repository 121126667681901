/* eslint-disable react/no-danger */
import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import MarkdownContent from '../components/MarkdownContent';

import '../components/language.css';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { frontmatter } = data.LanguagePage;
  return (
    <div>
      <SEO title={`Język ${frontmatter.language}`} />
      <Layout>
        <div className="language">
          <div className="language__post">
            <Img
              className="language__post-thumbnail"
              fluid={frontmatter.picture.childImageSharp.fluid}
              alt="Index Page"
            />
            <h1>Język {frontmatter.language}</h1>
            <MarkdownContent content={frontmatter.description} />

            {data.ScheduleItemsByLang && data.ScheduleItemsByLang.edges.length > 0 ? (
              <ul className="language__post-list">
                <h3 className="language__post-list-header">Plan zajęć:</h3>
                {data.ScheduleItemsByLang.edges.map(edge => (
                  <span>
                    {!!edge.node.frontmatter.or && <li>lub</li>}
                    <li key={edge.node.id}>
                      <strong>{edge.node.frontmatter.group}</strong> {edge.node.frontmatter.day}&nbsp;
                      godz. {edge.node.frontmatter.time} {`(${edge.node.frontmatter.room})`}
                    </li>
                  </span>
                ))}
              </ul>
            ) : (
              <h4 className="language__post-no-classes">
                W tym semestrze nie odbywają się zajęcia z tego języka
              </h4>
            )}
          </div>
          <div className="separator" />
        </div>
      </Layout>
    </div>
  );
}

Template.PropTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export const pageQuery = graphql`
  query LanguageInfo($id: String!, $language: String!) {
    LanguagePage: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        language
        picture {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        description
      }
    }
    ScheduleItemsByLang: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___group, frontmatter___position] }
      filter: { frontmatter: { templateKey: { eq: "schedule-item" }, language: { eq: $language } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            language
            group
            room
            time
            day
            or
            position
          }
        }
      }
    }
  }
`;
